/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

import './zone-flags';

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Polyfill for indexedDB.databases()
 * Safari and some other older browsers that support indexedDB do NOT
 * Support enumerating existing databases. This is problematic when it
 * comes time to cleanup, otherwise we could litter their device with
 * unreferenceable database handles forcing a nuclear browser clear all history.
 */

(function () {
  if (window.indexedDB && typeof window.indexedDB.databases === 'undefined') {
    const LOCALSTORAGE_CACHE_KEY = 'indexedDBDatabases';

    // Store a key value map of databases
    const getFromStorage = () => {
      if (
        !window.localStorage[LOCALSTORAGE_CACHE_KEY] &&
        !!window.localStorage['db-names']
      ) {
        var dbObject = {};
        JSON.parse(window.localStorage['db-names']).forEach(
          db => (dbObject[db] = 1),
        );
        return dbObject;
      }
      return JSON.parse(window.localStorage[LOCALSTORAGE_CACHE_KEY] || '{}');
    };

    // Write the database to local storage
    const writeToStorage = value =>
      (window.localStorage[LOCALSTORAGE_CACHE_KEY] = JSON.stringify(value));

    IDBFactory.prototype.databases = () =>
      Promise.resolve(
        Object.entries(getFromStorage()).reduce((acc, [name, version]) => {
          acc.push({ name, version });
          return acc;
        }, []),
      );

    // Intercept the existing open handler to write our DBs names
    // and versions to localStorage
    const open = IDBFactory.prototype.open;

    IDBFactory.prototype.open = function (...args) {
      const dbName = args[0];
      const version = args[1] || 1;
      const existing = getFromStorage();
      writeToStorage({ ...existing, [dbName]: version });
      return open.apply(this, args);
    };

    // Intercept the existing deleteDatabase handler remove our
    // dbNames from localStorage
    const deleteDatabase = IDBFactory.prototype.deleteDatabase;

    IDBFactory.prototype.deleteDatabase = function (...args) {
      const dbName = args[0];
      const existing = getFromStorage();
      delete existing[dbName];
      writeToStorage(existing);
      return deleteDatabase.apply(this, args);
    };
  }
})();
